<template>
  <div></div>
</template>
<script>
import { wxLogin } from "@/utils/api";
export default {
  created() {
    this.login();
  },
  methods: {
    async login() {
      const data = await wxLogin();
    },
  },
};
</script>